<template>
  <div style="z-index: 10000">
    <v-navigation-drawer v-model="drawerOpen" app disable-resize-watcher>
      <v-list class="mt-3" dense nav style="gap: 1rem; height: 100%">
        <v-list-item v-for="item in links" :key="item.id" class="py-2" link>
          <v-list-item-content>
            <router-link :to="item.link">{{ $t(`navbar.${item.id}`) }}</router-link>
          </v-list-item-content>
        </v-list-item>

        <v-divider class="my-4"></v-divider>

        <v-list-item v-for="item in rightLinks.filter((i) => i.auth === isLogged)" :key="item.id" class="py-2" link>
          <v-list-item-content>
            <router-link :to="item.link">{{ $t(`navbar.${item.id}`) }}</router-link>
          </v-list-item-content>
        </v-list-item>

        <v-list v-if="isLogged">
          <v-list-item class="">
            <v-list-item-content>
              <router-link class="btn-link" to="/profile">
                {{ $t("navbar.profile") }}
              </router-link>
            </v-list-item-content>
          </v-list-item>

          <v-list-item class="">
            <v-list-item-content>
              <BABtn color="primary" style="border-width: 2px" text width="100%" @click="logout">
                {{ $t("auth.logout.title") }}
                <v-icon dark right> mdi-logout</v-icon>
              </BABtn>
            </v-list-item-content>
          </v-list-item>
        </v-list>

        <v-list v-else>
          <router-link class="btn-link" tag="div" to="/login">
            <v-list-item class="">
              <v-list-item-content>
                <BABtn outlined style="border-width: 2px" width="100%">
                  {{ $t("auth.signup.button") }}
                </BABtn>
              </v-list-item-content>
            </v-list-item>
          </router-link>

          <router-link class="btn-link" tag="div" to="/login">
            <v-list-item class="">
              <v-list-item-content>
                <BABtn style="border-width: 2px" width="100%">
                  {{ $t("auth.signin.button") }}
                </BABtn>
              </v-list-item-content>
            </v-list-item>
          </router-link>
        </v-list>

        <v-list-item>
          <v-list-item-content>
            <LanguagesSwitcher />
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar class="blur-bg py-5 px-md-2 px-0" dense fixed flat style="height: fit-content !important">
      <span class="hidden-md-and-up" style="height: 100%">
        <v-app-bar-nav-icon class="me-2 me-md-0" @click="drawerOpen = !drawerOpen" />
      </span>

      <v-toolbar-title>
        <router-link to="/">
          <v-img
            :src="require('@/assets/images/logo/Color_logo_no_background.svg')"
            alt="ArtSharing Logo"
            max-width="100%"
            min-width="100px"
            width="250px"
          />
        </router-link>
      </v-toolbar-title>

      <v-divider class="mx-7 hidden-sm-and-down" inset vertical />

      <div
        class="hidden-sm-and-down align-center justify-space-between flex-nowrap flex-grow-1"
        style="height: inherit; display: flex"
      >
        <v-toolbar-items class="align-center">
          <router-link
            v-for="item in links"
            :key="item.id"
            :to="item.link"
            class="me-10 primary--text text-no-wrap"
            style="letter-spacing: 1px !important"
          >
            {{ $t(`navbar.${item.id}`) }}
          </router-link>
        </v-toolbar-items>

        <div class="d-flex align-center me-8" style="height: inherit">
          <v-toolbar-items class="align-center">
            <router-link
              v-for="item in rightLinks.filter((i) => i.auth === isLogged)"
              :key="item.id"
              :to="item.link"
              class="me-10 primary--text"
              style="letter-spacing: 1px !important"
            >
              {{ $t(`navbar.${item.id}`) }}
            </router-link>
          </v-toolbar-items>

          <v-text-field
            :placeholder="$t('navbar.search')"
            append-icon="mdi-magnify"
            class="hidden-md-and-down d-none"
            dense
            hide-details
            outlined
            rounded
          />

          <div v-if="isLogged" class="ml-0 d-flex flex-row flex-nowrap" style="gap: 1rem">
            <v-menu v-if="!$apollo.loading && me && me.firstName" offset-y style="z-index: 11000">
              <template #activator="{ on, attrs }">
                <ProfileAvatar :user="me" v-bind="attrs" v-on="on" />
              </template>
              <v-list>
                <router-link
                  v-for="(profileLink, index) in profileLinks"
                  :key="index"
                  :to="profileLink.link"
                  class="font-weight-regular"
                >
                  <v-list-item>
                    <v-list-item-title>{{ $t("navbar." + profileLink.id) }}</v-list-item-title>
                  </v-list-item>
                </router-link>
                <v-list-item @click="logout()">
                  <v-list-item-title>Logout</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </div>

          <div v-else class="d-flex flex-row flex-nowrap align-center justify-center ml-5" style="gap: 1rem">
            <v-btn
              class="px-5"
              color="accent"
              large
              outlined
              rounded
              style="border-width: 2px"
              @click="$router.push({ name: 'signup' })"
            >
              {{ $t("auth.signup.button") }}
            </v-btn>

            <v-btn class="px-7" color="primary" large rounded @click="$router.push({ name: 'login' })">
              {{ $t("auth.signin.button") }}
            </v-btn>
          </div>

          <div class="ps-md-10 ps-0">
            <LanguagesSwitcher />
          </div>
          {{ $store.state.logged }}
        </div>
      </div>
    </v-app-bar>
  </div>
</template>

<script>
import LanguagesSwitcher from "@/components/LanguagesSwitcher";
import { profileQuery } from "@/graphql/queries";
import axios from "axios";
import BABtn from "@/components/ui/BABtn";
import ProfileAvatar from "@/components/ProfileAvatar";

export default {
  name: "NavBar",
  components: { ProfileAvatar, BABtn, LanguagesSwitcher },
  data() {
    return {
      me: null,
      drawerOpen: false,
      links: [
        {
          id: "marketplace",
          link: "/marketplace",
        },
      ],
      rightLinks: [
        {
          id: "start_selling",
          link: "/sell",
          auth: false,
        },
        {
          id: "start_collecting",
          link: "/collect",
          auth: false,
        },
        {
          id: "upload_artwork",
          link: "/sell",
          auth: true,
        },
        {
          id: "collect_artworks",
          link: "/collect",
          auth: true,
        },
        {
          id: "my_galleries",
          link: "/mygalleries",
          auth: true,
        },
        {
          id: "my_collection",
          link: "/mycollection",
          auth: true,
        },
        {
          id: "orders_offers",
          link: "/orders",
          auth: true,
        },
      ],
      profileLinks: [
        {
          id: "profile",
          link: "/profile",
        },
      ],
    };
  },
  computed: {
    isLogged() {
      return this.$store.getters.isLogged;
    },
  },
  apollo: {
    me: {
      query: profileQuery(["firstName", "lastName", "initials", "profilePicture"]),
      fetchPolicy: "cache-first",
      skip() {
        return !this.$store.getters.isLogged;
      },
    },
  },
  methods: {
    logout() {
      axios.post("/auth/logout").then(async () => {
        await this.$store.commit("logout", this.$apollo);
        const newPath = "/";
        if (this.$route.path !== newPath) return this.$router.push(newPath);
      });
    },
  },
};
</script>

<style scoped>
.v-list-item__content {
  overflow: visible;
}

.btn-link {
  height: inherit;
}

a {
  text-decoration: none;
  font-size: 16px;
  font-weight: 500;
  color: black !important;
}
</style>
<style>
.blur-bg {
  backdrop-filter: blur(8px);
  background: rgba(255, 255, 255, 0.85) !important;
}
</style>
