import { gqlize } from "./utils";
import { mutation } from "gql-query-builder";

export const REGISTER = gqlize(
  mutation({
    operation: "register",
    variables: {
      firstName: { type: "String", required: true },
      lastName: { type: "String", required: true },
      email: { type: "String", required: true },
      password1: { type: "String", required: true },
      password2: { type: "String", required: true },
    },
    fields: ["success", "errors"],
  }),
);

export const LOGIN = gqlize(
  mutation({
    operation: "login",
    variables: {
      email: { type: "String", required: true },
      password: { type: "String", required: true },
    },
    fields: [
      "success",
      "errors",
      {
        user: ["id", "email"],
      },
    ],
  }),
);

export const PASSWORD_RESET = gqlize(
  mutation({
    operation: "sendPasswordResetEmail",
    variables: {
      email: { type: "String", required: true },
    },
    fields: ["success", "errors"],
  }),
);

export const PASSWORD_RESET_CHANGE = gqlize(
  mutation({
    operation: "passwordReset",
    variables: {
      token: { type: "String", required: true },
      newPassword1: { type: "String", required: true },
      newPassword2: { type: "String", required: true },
    },
    fields: ["success", "errors"],
  }),
);

export const CHANGE_PASSWORD = gqlize(
  mutation({
    operation: "changePassword",
    variables: {
      currentPassword: { type: "String", required: true },
      password1: { type: "String", required: true },
      password2: { type: "String", required: true },
    },
    fields: ["success", "errors"],
  }),
);

export const UPLOAD_PROPIC = gqlize(
  mutation({
    operation: "uploadPropic",
    variables: {
      propic: { type: "Upload", required: true },
    },
    fields: ["success", "errors"],
  }),
);

export const ERASE_PROPIC = gqlize(
  mutation({
    operation: "erasePropic",
    fields: ["success", "errors"],
  }),
);

export const SIGN_DOCUMENT = gqlize(
  mutation({
    operation: "sign",
    variables: {
      document: { type: "String", required: true },
    },
    fields: ["success", "errors"],
  }),
);

export const ADD_WALLET = gqlize(
  mutation({
    operation: "addWallet",
    variables: {
      signature: { type: "String", required: true },
      type: { type: "String", required: true },
      address: { type: "String", required: true },
      originalMessage: { type: "String", required: true },
    },
    fields: ["success", "errors"],
  }),
);

export const SET_DEFAULT_WALLET = gqlize(
  mutation({
    operation: "setDefaultWallet",
    variables: {
      address: { type: "String", required: true },
    },
    fields: ["success", "errors"],
  }),
);

export const CREATE_GALLERY = gqlize(
  mutation({
    operation: "createGallery",
    variables: {
      title: { type: "String", required: true },
      description: { type: "String", required: true },
      logo: { type: "Upload", required: true },
      cover: { type: "Upload", required: true },
    },
    fields: ["success", "errors", { gallery: ["slug"] }],
  }),
);

export const CREATE_ARTWORK = gqlize(
  mutation({
    operation: "createArtwork",
    variables: {
      gallery: { name: "gallerySlug", type: "String", required: true },
      title: { type: "String", required: true },
      description: { type: "String", required: true },
      author: { type: "String", required: true },
      year: { type: "Int", required: true },
      technique: { type: "String", required: true },
      custodian: { type: "String", required: true },
      artisticMovement: { type: "String", required: true },
      location: { type: "String", required: true },
      tags: { type: "[String]", required: true },
      value: { name: "totalValue", type: "Decimal", required: true },
      tokenSupply: { type: "Decimal", required: true },
      initialOfferTokensPct: { type: "Decimal", required: true },
      rentFee: { name: "rentFee", type: "Decimal", required: true },
      notes: { type: "String", required: true },
      gekart: { type: "String" },
      lowRes: { type: "Upload", required: true },
      model3dHighResUploadPk: { type: "ID" },
      imageHighResUploadPk: { type: "ID" },
      authenticityAttach: { type: "Upload", required: true },
      conditionAttach: { type: "Upload", required: true },
      attachs: { name: "otherAttachs", type: "[Upload]", required: true },
      extraPhotos: { name: "extraPhotos", type: "[Upload]", required: true },
    },
    fields: ["success", "errors", { artwork: ["slug"] }],
  }),
);

export const PUBLISH_ARTWORK = gqlize(
  mutation({
    operation: "publishArtwork",
    variables: {
      slug: { type: "String", required: true },
      walletAddress: { type: "String", required: true },
    },
    fields: ["success", { artwork: ["published"] }, "errors"],
  }),
);

export const UPDATE_ARTWORK = gqlize(
  mutation({
    operation: "updateArtwork",
    variables: {
      id: { type: "ID", required: true },
      initialTotalValue: { type: "Decimal" },
      tokenSupply: { type: "Int" },
      initialOfferTokensPct: { type: "Decimal" },
      rentFee: { type: "Decimal" },
      title: { type: "String" },
      author: { type: "String" },
      tags: { type: "[String]" },
      year: { type: "Int" },
      technique: { type: "String" },
      artisticMovement: { type: "String" },
      custodian: { type: "String" },
      location: { type: "String" },
      description: { type: "String" },
      gekart: { type: "String" },
      imageLowRes: { type: "Upload" },
    },
    fields: ["success", "errors", { artwork: ["slug", "imageLowRes"] }],
  }),
);

export const ADD_ARTWORK_PHOTOS = gqlize(
  mutation({
    operation: "addArtworkPhotos",
    variables: {
      artworkId: { type: "ID", required: true },
      photos: { type: "[Upload]", required: true },
    },
    fields: ["success", "errors", { artworkPhotos: ["pk", "url"] }],
  }),
);

export const DELETE_ARTWORK_PHOTO = gqlize(
  mutation({
    operation: "deleteArtworkPhoto",
    variables: {
      id: { type: "ID", required: true },
    },
    fields: ["success", "errors"],
  }),
);

export const DELETE_ARTWORK = gqlize(
  mutation({
    operation: "deleteArtwork",
    variables: {
      id: { type: "ID", required: true },
    },
    fields: ["success", "errors"],
  }),
);

export const CREATE_SWEEP_ORDER = gqlize(
  mutation({
    operation: "createSweepOrder",
    variables: {
      artworkId: {
        type: "ID",
        required: true,
      },
      walletAddress: {
        type: "String",
        required: true,
      },
      maxQuantity: {
        type: "Int",
        required: true,
      },
      maxPricePerToken: {
        type: "Decimal",
        required: true,
      },
      maxTotalCost: {
        type: "Decimal",
        required: true,
      },
      txHash: {
        type: "String",
        required: true,
      },
    },
    fields: ["success", "errors"],
  }),
);

export const INITIAL_SELL_ORDER = gqlize(
  mutation({
    operation: "initialSellOrder",
    variables: {
      artworkId: { type: "ID", required: true },
      walletAddress: { type: "String", required: true },
      txHash: { type: "String", required: true },
      tokenPrice: { type: "Decimal", required: true },
    },
    fields: ["success", "errors"],
  }),
);

export const CREATE_SELL_ORDER = gqlize(
  mutation({
    operation: "createSellOrder",
    variables: {
      artworkId: {
        type: "ID",
        required: true,
      },
      walletAddress: {
        type: "String",
        required: true,
      },
      quantity: {
        type: "Int",
        required: true,
      },
      price: {
        type: "Decimal",
        required: true,
      },
      txHash: {
        type: "String",
        required: true,
      },
    },
    fields: ["success", "errors"],
  }),
);

export const CANCEL_SELL_ORDER = gqlize(
  mutation({
    operation: "cancelSellOrder",
    variables: {
      orderId: {
        type: "ID",
        required: true,
      },
      txHash: {
        type: "String",
        required: true,
      },
    },
    fields: ["success", "errors"],
  }),
);

export const ACQUIRE_ARTWORK = gqlize(
  mutation({
    operation: "acquireArtwork",
    variables: {
      artwork: { type: "ID", required: true },
      walletAddress: { type: "String", required: true },
      price: { type: "Decimal", required: true },
    },
    fields: ["success", "errors"],
  }),
);

export const REJECT_ACQUISITION = gqlize(
  mutation({
    operation: "rejectAcquisition",
    variables: {
      acquisition: { type: "ID", required: true },
    },
    fields: ["success", "errors"],
  }),
);

export const CANCEL_ACQUISITION = gqlize(
  mutation({
    operation: "cancelAcquisition",
    variables: {
      acquisition: { type: "ID", required: true },
    },
    fields: ["success", "errors"],
  }),
);

export const ACCEPT_ACQUISITION = gqlize(
  mutation({
    operation: "acceptAcquisition",
    variables: {
      acquisition: { type: "ID", required: true },
    },
    fields: ["success", "errors"],
  }),
);

export const RENT_ARTWORK = gqlize(
  mutation({
    operation: "rentArtwork",
    variables: {
      artworkId: { type: "ID", required: true },
      dateString: { type: "String", required: true },
      days: { type: "Int", required: true },
      walletAddress: { type: "String", required: true },
    },
    fields: ["success", "errors"],
  }),
);

export const REVIEW_ARTWORK = gqlize(
  mutation({
    operation: "reviewArtwork",
    variables: {
      id: { type: "ID", required: true },
    },
    fields: ["success", "errors"],
  }),
);

export const CREATE_ARTWORK_REQUEST = gqlize(
  mutation({
    operation: "createArtworkRequest",
    variables: {
      author: { type: "String", required: true },
      title: { type: "String", required: true },
      photos: { type: "[Upload]", required: true },
      certificates: { type: "[Upload]", required: true },
      valueEstimate: { type: "Decimal", required: true },
      notes: { type: "String", required: true },
    },
    fields: ["success", "errors"],
  }),
);
