import Vuex from "vuex";
import authModule from "./modules/auth.js";
import tradingModule from "./modules/trading.js";
import Vue from "vue";
import VuexPersistence from "vuex-persist";

Vue.use(Vuex);

const vuexLocal = new VuexPersistence({
  storage: window.localStorage,
});

const store = new Vuex.Store({
  modules: {
    auth: authModule,
    trading: tradingModule,
  },
  plugins: [vuexLocal.plugin],
});

export default store;
