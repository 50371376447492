<template>
  <v-app>
    <v-main>
      <component :is="layout">
        <router-view></router-view>
      </component>
    </v-main>
  </v-app>
</template>

<script>
import { createWeb3Modal, defaultConfig } from "@web3modal/ethers/vue";
import { FRONTEND_CONFIG } from "@/utils/config";

const default_layout = "default";

export default {
  setup() {
    // 1. Get projectId at https://cloud.walletconnect.com
    const projectId = FRONTEND_CONFIG.WALLET_CONNECT_PROJECT_ID;
    const chainId = FRONTEND_CONFIG.CHAIN_ID;

    // 2. Set chains
    const polygon = {
      chainId: 137,
      name: "Polygon",
      currency: "MATIC",
      explorerUrl: "https://polygonscan.com/",
      rpcUrl: "https://polygon-rpc.com",
    };

    const amoy = {
      chainId: 80002,
      name: "Amoy",
      currency: "MATIC",
      explorerUrl: "https://amoy.polygonscan.com/",
      rpcUrl: "https://rpc-amoy.polygon.technology",
    };

    const chainsById = {
      [polygon.chainId]: polygon,
      [amoy.chainId]: amoy,
    };

    // 3. Create modal
    const metadata = {
      name: "ArtSharing",
      description: "ArtSharing",
      url: "https://artsharing.com/",
    };

    const currentChain = chainsById[chainId];
    console.log("currentChain", currentChain);

    createWeb3Modal({
      ethersConfig: defaultConfig({ metadata }),
      chains: [currentChain],
      projectId,
    });
  },
  computed: {
    layout() {
      return (this.$route.meta.layout || default_layout) + "Layout";
    },
  },
  beforeMount() {
    this.$vuetify.rtl = this.$root.$i18n.locale === "ar";
  },
};
</script>

<style>
@import "./assets/css/variables.css";

html {
  overflow: auto !important;
}

.fill-width {
  max-width: 100% !important;
  min-width: calc(100vw - 8px) !important;
  margin-left: calc(50% - 50vw) !important;
  /*problem: the 100vw width will ignore the vertical scrollbar, that takes space, therefore there*/
  /*will be a horizontal scrollbar.*/
}

.page-painting {
  filter: drop-shadow(-20px 20px 32px rgba(0, 0, 0, 0.33));
}
</style>
