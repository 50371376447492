<template>
  <div :class="`d-flex flex-column fill-height ${$route.meta.dark ? 'primary' : 'white'}`">
    <NavBar class="flex-shrink-1" style="min-height: 88px" />
    <v-container
      fluid
      :class="`fill-height align-start justify-start pa-0 ${
        $route.meta.fillPage ? '' : 'px-5 limit-width'
      }`">
      <slot />
    </v-container>
  </div>
</template>

<script>
import NavBar from "@/components/Navbar";

export default {
  name: "DefaultLayout",
  components: { NavBar }
};
</script>
