import VueApollo from "vue-apollo";
import Vue from "vue";
import Cookies from "js-cookie";

import { ApolloClient } from "apollo-client";
import { ApolloLink, from } from "apollo-link";
import { InMemoryCache } from "apollo-cache-inmemory";
import router from "@/router/router";
import { asyncMap } from "apollo-client/util/observables";
import store from "@/store";
import { createUploadLink } from "apollo-upload-client";

const csrfLink = new ApolloLink((operation, forward) => {
  const csrftoken = Cookies.get("csrftoken");
  operation.setContext(({ headers }) => ({
    headers: {
      ...headers,
      "X-CSRFToken": csrftoken,
    },
  }));

  return forward(operation);
});

const graphqlLink = createUploadLink({
  uri: "/graphql/",
  credentials: "same-origin",
});

const refreshTokenLink = new ApolloLink((operation, forward) => {
  return asyncMap(forward(operation), async (response) => {
    // check if the request contains at least one invalid request
    let unauthorized_request = false;
    for (const [query_key, query_output] of Object.entries(response.data)) {
      unauthorized_request =
        unauthorized_request ||
        query_output?.errors?.nonFieldErrors?.code === "unauthenticated" || // if the response is unauthenticated (mutations)
        query_output?.errors?.unauthorized !== undefined ||
        (query_output === null && query_key === "me"); // if the response is null and the query is me
    }

    // if there is any request that failed because of the lack of authorization
    if (unauthorized_request) {
      store.commit("logout");
      router.replace("/?logout");
    }
    return response;
  });
});

const defaultOptions = {
  watchQuery: {
    fetchPolicy: "no-cache",
  },
  query: {
    fetchPolicy: "no-cache",
  },
  mutate: {
    fetchPolicy: "no-cache",
  },
};

const apolloClient = new ApolloClient({
  link: from([csrfLink, refreshTokenLink, graphqlLink]),
  cache: new InMemoryCache(),
  defaultOptions,
});

Vue.use(VueApollo);

const apolloProvider = new VueApollo({
  defaultClient: apolloClient,
});

export default apolloProvider;
