import Vue from "vue";
import VueI18n from "vue-i18n";
import Cookies from "js-cookie";

Vue.use(VueI18n);

const AVAILABLE_LANGUAGES = ["en", "it", "fr", "ru", "ar"];

function loadLocaleMessages() {
  const locales = require.context("./locales", true, /[A-Za-z0-9-_,\s]+\.json$/i);
  const messages = {};
  locales.keys().forEach((key) => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i);
    if (matched && matched.length > 1) {
      const locale = matched[1];
      messages[locale] = locales(key);
    }
  });
  return messages;
}

function getNavigatorLocale() {
  const browserLanguage = navigator?.language;
  if (browserLanguage && browserLanguage.includes("-")) {
    return browserLanguage.split("-")[0];
  }
  return browserLanguage;
}

function getCurrentLocale() {
  if (Cookies.get("ba-lang")) return Cookies.get("ba-lang");
  const browserLanguage = getNavigatorLocale();
  // if the browser language is a supported language, use it
  const language = AVAILABLE_LANGUAGES.includes(browserLanguage)
    ? browserLanguage
    : process.env.VUE_APP_I18N_LOCALE || "en";
  Cookies.set("ba-lang", language);
  return language;
}

const commonDateTimeFormat = {
  short: {
    year: "numeric",
    month: "long",
    day: "numeric",
  },
  long: {
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
  },
  complete: {
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
  },
};

const dateTimeFormats = {
  en: commonDateTimeFormat,
  it: commonDateTimeFormat,
  fr: commonDateTimeFormat,
  ru: commonDateTimeFormat,
  ar: commonDateTimeFormat,
};

const commonNumberFormat = {
  percent: {
    style: "percent",
    minimumFractionDigits: 2,
  },
  decimal: {
    style: "decimal",
    minimumFractionDigits: 2,
  },
  integer: {
    style: "decimal",
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  },
  compact: {
    notation: "compact",
    maximumFractionDigits: 2,
  },
};

const numberFormats = {
  en: commonNumberFormat,
  it: commonNumberFormat,
  fr: commonNumberFormat,
  ru: commonNumberFormat,
  ar: commonNumberFormat,
};

export default new VueI18n({
  locale: getCurrentLocale(),
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || "en",
  messages: loadLocaleMessages(),
  dateTimeFormats,
  numberFormats,
});
