<template>
  <v-menu left offset-y transition="slide-y-transition" z-index="11000">
    <template #activator="{ on, attrs }">
      <div class="d-flex flex-row justify-center" v-bind="attrs" v-on="on">
        <span class="text-h6 ml-2 primary--text">{{ currentLocale.toUpperCase() }}</span>
        <v-icon class="ml-1 black--text">mdi-chevron-down</v-icon>
      </div>
    </template>
    <v-list>
      <v-list-item
        v-for="(item, index) in availableLanguages"
        :key="index"
        style="cursor: pointer"
        @click="optionSelected(item)">
        <v-list-item-title>{{ item }}</v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import Cookies from "js-cookie";

export default {
  name: "LanguagesSwitcher",
  data() {
    let languages = {
      en: "English",
      it: "Italiano",
      ar: "عربي",
      ru: "Русский",
      fr: "Français",
    };
    return {
      availableLanguages: Object.keys(this.$root.$i18n.messages).map(function (code) {
        return languages[code];
      }),
      languages: languages,
    };
  },
  computed: {
    currentLocale() {
      return this.$root.$i18n.locale;
    },
  },
  methods: {
    optionSelected: function (data) {
      let locale = "en";
      for (const [key, value] of Object.entries(this.languages)) {
        if (value === data) locale = key;
      }
      this.$vuetify.rtl = locale === "ar";
      Cookies.set("ba-lang", locale);
      this.$root.$i18n.locale = locale;
    },
  },
};
</script>
