const tradingModule = {
  state: {
    usdcMessageAccepted: false,
  },
  getters: {
    isTradingEnabled() {
      return process.env.VUE_APP_TRADING_ENABLED === "true";
    },
    usdcMessageAccepted(state) {
      return state.usdcMessageAccepted;
    },
  },
  mutations: {
    acceptUsdcMessage(state) {
      state.usdcMessageAccepted = true;
    },
  },
};

export default tradingModule;
