<template>
  <v-tooltip v-if="disabled && disabledMessage" top>
    <template #activator="{ on, attrs }">
      <div v-bind="attrs" v-on="on">
        <v-btn
          :class="`${small ? 'px-5 py-6' : 'px-7 py-5'} text-capitalize elevation-0 `"
          :color="color"
          :x-large="!small"
          disabled
          rounded
          v-bind="$attrs">
          <v-icon v-if="prependIcon">{{ prependIcon }}</v-icon>
          <slot />
        </v-btn>
      </div>
    </template>
    <span v-if="disabledMessage">{{ disabledMessage }}</span>
  </v-tooltip>
  <v-btn
    v-else
    :class="`${small ? 'px-5 py-6' : 'px-7 py-5'} text-capitalize elevation-0 `"
    style="width: fit-content !important"
    :color="color"
    :dark="!disabled"
    :disabled="disabled"
    :x-large="!small"
    rounded
    v-bind="$attrs"
    v-on="$listeners">
    <v-icon v-if="prependIcon" class="pe-2">{{ prependIcon }}</v-icon>
    <slot />
  </v-btn>
</template>

<script>
export default {
  name: "BABtn",
  props: {
    color: {
      type: String,
      default: "accent",
    },
    small: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    disabledMessage: {
      type: String,
      default: "",
    },
    prependIcon: {
      type: String,
      default: "",
    },
  },
};
</script>
