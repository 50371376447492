import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    options: {
      customProperties: true,
    },
    themes: {
      light: {
        primary: "#203E76",
        "primary-1": "#336CAA",
        "primary-2": "#151C40",

        secondary: "#FDB62C",
        tertiary: "#683BAD",

        accent: "#33AADF",
        "accent-1": "#A3D9F1",
        "accent-2": "#F1F9FD",

        finance: "#40D462",
        "finance-1": "#2FA74B",
        "finance-2": "#ECFBEF",

        surface: "#F5F8FF",
        "surface-2": "#F0F3FA",
        white: "#FFFFFF",
        error: "#EB5463",
        warning: "#F5B945",
        success: "#28a745",

        "dark-900": "#181819",
        "dark-800": "#37373D",
        "dark-700": "#54555F",
        "dark-600": "#676875",
        "dark-500": "#8D8FA0",
        "dark-400": "#ACAEB9",
      },
    },
  },
});
