const routes = [
  {
    path: "/",
    name: "home",
    meta: {
      title: "ArtSharing",
    },
    component: () => import(/* webpackChunkName: "homepage" */ "../views/HomePage.vue"),
  },
  {
    path: "/signup",
    name: "signup",
    meta: {
      title: "ArtSharing",
      fillPage: true,
    },
    component: () => import(/* webpackChunkName: "signup" */ "../views/SignUp.vue"),
  },
  {
    path: "/login",
    name: "login",
    meta: {
      title: "ArtSharing",
      dark: true,
      fillPage: true,
    },
    component: () => import(/* webpackChunkName: "login" */ "../views/LogIn.vue"),
  },
  {
    path: "/password-reset",
    name: "password-reset",
    meta: {
      title: "ArtSharing",
      dark: true,
      fillPage: true,
    },
    component: () => import(/* webpackChunkName: "password-reset" */ "../views/PasswordReset.vue"),
  },
  {
    path: "/password-reset/:token",
    name: "password-reset-change",
    meta: {
      title: "ArtSharing",
      fillPage: true,
    },
    component: () => import(/* webpackChunkName: "password-reset-change" */ "../views/PasswordResetChangePassword"),
  },
  {
    path: "/profile",
    name: "profile",
    redirect: {
      name: "profile_wallet",
    },
    meta: {
      title: "ArtSharing",
      login_required: true,
    },
    component: () => import(/* webpackChunkName: "dashboard" */ "../views/Profile"),
    children: [
      {
        path: "wallet",
        name: "profile_wallet",
        component: () => import(/* webpackChunkName: "profile_wallet" */ "../views/ProfileWallet"),
      },
      {
        path: "contracts",
        name: "profile_contracts",
        component: () => import(/* webpackChunkName: "profile_contracts" */ "../views/ProfileContracts"),
      },
      {
        path: "identity",
        name: "profile_identity",
        component: () => import(/* webpackChunkName: "profile_identity" */ "../views/ProfileIdentity"),
      },
      {
        path: "settings",
        name: "profile_settings",
        component: () => import(/* webpackChunkName: "profile_settings" */ "../views/ProfileSettings"),
      },
    ],
  },
  {
    path: "/galleries/new",
    name: "gallery-new",
    meta: {
      title: "ArtSharing",
      login_required: true,
    },
    component: () => import(/* webpackChunkName: "gallery-new" */ "../views/GalleryCreate"),
  },
  {
    path: "/galleries/:gallery_id",
    name: "gallery-info",
    meta: {
      title: "ArtSharing",
    },
    component: () => import(/* webpackChunkName: "gallery-info" */ "../views/GalleryView"),
  },
  {
    path: "/galleries/:gallery_id/artworks/new",
    name: "artwork-new",
    meta: {
      title: "ArtSharing",
      login_required: true,
    },
    component: () => import(/* webpackChunkName: "artwork-new" */ "../views/ArtworkCreate"),
  },
  {
    path: "/galleries/:gallery_id/artworks/:artwork_id",
    name: "artwork-info",
    meta: {
      title: "ArtSharing",
    },
    component: () => import(/* webpackChunkName: "artwork-info" */ "../views/ArtworkView"),
  },
  {
    path: "/galleries/:gallery_id/artworks/:artwork_id/viewer",
    name: "artwork-viewer",
    meta: {
      title: "ArtSharing",
      fillPage: true,
      layout: "none",
      login_required: true,
    },
    component: () => import(/* webpackChunkName: "artwork-viewer" */ "../views/ArtworkModelView"),
  },
  {
    path: "/galleries/:gallery_id/artworks/:artwork_id/hd-viewer",
    name: "artwork-image-high-res-viewer",
    meta: {
      title: "ArtSharing",
      fillPage: true,
      layout: "none",
      login_required: true,
    },
    component: () => import(/* webpackChunkName: "artwork-hd-imageviewer" */ "../views/ArtworkImageHighResView"),
  },
  {
    path: "/marketplace",
    name: "marketplace",
    meta: {
      title: "ArtSharing",
      fillPage: true,
    },
    component: () => import(/* webpackChunkName: "marketplace" */ "../views/MarketplaceView"),
  },
  {
    path: "/mycollection",
    name: "mycollection",
    meta: {
      title: "ArtSharing",
      login_required: true,
      fillPage: true,
    },
    component: () => import(/* webpackChunkName: "mycollection" */ "../views/MyCollection"),
  },
  {
    path: "/mygalleries",
    name: "mygalleries",
    meta: {
      title: "My Galleries - ArtSharing",
      fillPage: true,
      login_required: true,
    },
    component: () => import(/* webpackChunkName: "mygalleries" */ "../views/MyGalleries"),
  },
  {
    path: "/orders",
    name: "orders",
    meta: {
      title: "ArtSharing",
      login_required: true,
    },
    component: () => import(/* webpackChunkName: "orders" */ "../views/ProfileOrders"),
  },
  {
    path: "/sell",
    name: "sell",
    meta: {
      title: "ArtSharing",
      login_required: false,
      fillPage: true,
    },
    component: () => import(/* webpackChunkName: "sell" */ "../views/StartSellingView"),
  },
  {
    path: "/collect",
    name: "collect",
    meta: {
      title: "ArtSharing",
      login_required: false,
      fillPage: true,
    },
    component: () => import(/* webpackChunkName: "sell" */ "../views/StartCollectingView"),
  },
];

export { routes };
