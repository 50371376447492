<template>
  <v-hover>
    <template #default="{ hover }">
      <v-img
        :aspect-ratio="1"
        :src="src.length > 0 ? src : require('@/assets/images/default_propic.png')"
        class="rounded-circle white flex-shrink-1 propic">
        <v-fade-transition>
          <v-overlay v-if="owner && hover" absolute opacity=".5">
            <div
              class="d-flex flex-column justify-space-around align-center"
              style="height: 100%; width: 100%">
              <v-btn icon @click="selectFile">
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
              <input
                ref="propicUpload"
                :multiple="false"
                hidden
                accept="image/*"
                type="file"
                @change="updatePropic" />
              <v-btn color="error" icon @click="erasePropic">
                <v-icon>mdi-delete</v-icon>
              </v-btn>
            </div>
          </v-overlay>
        </v-fade-transition>
      </v-img>
    </template>
  </v-hover>
</template>

<script>
import { ERASE_PROPIC, UPLOAD_PROPIC } from "@/graphql/mutations";
import { profileQuery } from "@/graphql/queries";

export default {
  name: "ProfilePicture",
  props: {
    owner: {
      type: Boolean,
      default: false
    },
    src: {
      type: String,
      default: ""
    }
  },
  methods: {
    selectFile () {
      this.$refs.propicUpload?.click();
    },
    updatePropic (uploadEvent) {
      const propicFile = uploadEvent.target.files[0];
      this.$apollo.mutate({
        mutation: UPLOAD_PROPIC,
        variables: {
          propic: propicFile
        },
        refetchQueries: [
          {
            query: profileQuery(["profilePicture"])
          }
        ]
      });
    },
    erasePropic () {
      this.$apollo.mutate({
        mutation: ERASE_PROPIC,
        refetchQueries: [
          {
            query: profileQuery(["profilePicture"])
          }
        ]
      });
    }
  },
};
</script>

<style scoped>
>>> .v-overlay__content {
  height: 100%;
  width: 100%;
}
</style>
