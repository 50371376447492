<template>
  <v-container fluid class="d-flex flex-column" style="width: 100%; height: 100%">
    <div class="d-flex justify-center mt-4 flex-shrink-1">
      <v-col class="col-12 d-flex justify-center align-center">
        <div class="d-block" style="width: min(250px, 100%)">
          <router-link to="/" style="cursor: pointer" tag="div">
            <v-img :src="require('@/assets/images/logo/Color_logo_no_background.svg')" max-width="250px" />
          </router-link>
        </div>
      </v-col>
    </div>
    <slot />
  </v-container>
</template>

<script>
export default {
  name: "LogoOnly",
};
</script>

<style scoped></style>
