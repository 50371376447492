import Vue from "vue";
import { routes } from "./routes";
import VueRouter from "vue-router";
import store from "@/store";

Vue.use(VueRouter);

const router = new VueRouter({
  routes: routes,
  mode: "history"
});

const DEFAULT_TITLE = "ArtSharing";
router.afterEach((to) => {
  // Use next tick to handle router history correctly
  // see: https://github.com/vuejs/vue-router/issues/914#issuecomment-384477609
  Vue.nextTick(() => {
    document.title = to.meta.title || DEFAULT_TITLE;
  });
});

router.beforeEach((to, from, next) => {
  if (to.meta?.login_required === true && !store.getters.isLogged) {
    next({ name: "home", replace: true });
  } else {
    next();
  }
});

export default router;
