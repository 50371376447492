import Vue from "vue";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import i18n from "./i18n";
import store from "@/store/index";
import router from "./router/router";
import apolloProvider from "./plugins/apollo";
import Default from "@/layouts/Default";
import LogoOnly from "@/layouts/LogoOnly";
import None from "@/layouts/None";
import axios from "axios";

import VueFullscreen from "vue-fullscreen";

Vue.use(VueFullscreen);

Vue.component("DefaultLayout", Default);
Vue.component("LogoOnlyLayout", LogoOnly);
Vue.component("NoneLayout", None);

Vue.config.productionTip = false;

axios.defaults.xsrfHeaderName = "X-CSRFToken";
axios.defaults.xsrfCookieName = "csrftoken";

new Vue({
  store,
  vuetify,
  apolloProvider,
  i18n,
  router,
  render: (h) => h(App)
}).$mount("#app");
