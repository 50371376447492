import { query } from "gql-query-builder";
import { gqlize } from "./utils";

export const profileQuery = (fields) => {
  return gqlize(
    query({
      operation: "me",
      fields,
    }),
  );
};

export const SIGNATURE_MESSAGE = gqlize(
  query({
    operation: "signatureMessage",
    variables: {
      address: {
        type: "String",
        required: true,
      },
    },
  }),
);

export const CONNECTED_WALLETS = gqlize(
  query({
    operation: "connectedWallets",
    fields: ["address", "type", "enabledForTrading"],
  }),
);

export const galleryQuery = (fields) => {
  return gqlize(
    query({
      operation: "gallery",
      variables: {
        slug: {
          type: "String",
          required: true,
        },
      },
      fields,
    }),
  );
};

export const artworkQuery = (fields) =>
  gqlize(
    query({
      operation: "artwork",
      variables: {
        slug: {
          type: "String",
          required: true,
        },
      },
      fields,
    }),
  );

export const ARTWORK_DEFAULT_QUERY = artworkQuery([
  "pk",
  "title",
  "author",
  "slug",
  "description",
  "year",
  "tags",
  "technique",
  "artisticMovement",
  "custodian",
  "location",
  { gallery: ["id", "title", "slug", "cover"] },
  "initialTotalValue",
  "tokenSupply",
  "initialOfferTokensPct",
  "initialOfferTokens",
  "rentFee",
  "gekart",
  "imageLowRes",
  "hasModel3dHighRes",
  "hasImageHighRes",
  { extraPhotos: ["pk", "url"] },
  "published",
  "initialSaleStarted",
  "tradingStatus",
  "owner",
  "notes",
  "coownedUnits",
  "availableCoownedUnits",
  "coownedUnitsOnSale",
  "attachments{id,file}",
  "rejectionReason",
  "requestedChanges",
  "tokenId",
]);

const BASE_ARTWORK_FIELDS = [
  "id",
  "slug",
  "title",
  "imageLowRes",
  "tokenSupply",
  "initialOfferTokensPct",
  "initialOfferTokens",
  "initialTotalValue",
  "createdAt",
  "owner",
  "coownedUnits",
  { gallery: ["id", "slug", "title"] },
];

export const PRICE_DATA_FOR_ARTWORK = gqlize(
  query({
    operation: "priceDataForArtwork",
    variables: {
      artworkId: {
        type: "ID",
        required: true,
      },
    },
    fields: ["timestamp", "price"],
  }),
);

export const ARTWORK_MODEL_3D_HIGH_RES = gqlize(
  query({
    operation: "artworkModel3dHighRes",
    variables: {
      id: {
        type: "ID",
        required: true,
      },
    },
  }),
);

export const ARTWORK_IMAGE_HIGH_RES = gqlize(
  query({
    operation: "artworkImageHighRes",
    variables: {
      id: {
        type: "ID",
        required: true,
      },
    },
  }),
);

export const ARTWORK_EPT_PERCENTAGE = gqlize(
  query({
    operation: "artwork",
    variables: {
      slug: {
        type: "String",
        required: true,
      },
    },
    fields: ["eptPercentage"],
  }),
);

export const ALL_TRANSACTIONS = gqlize(
  query({
    operation: "transactions",
    variables: {
      artwork: {
        type: "ID",
        required: true,
      },
    },
    fields: [
      "id",
      { fromWallet: ["address"] },
      { toWallet: ["address"] },
      "amount",
      "price",
      "transactionType",
      "updatedAt",
    ],
  }),
);

export const SELL_ORDERS = gqlize(
  query({
    operation: "sellOrders",
    variables: {
      artwork: {
        type: "ID",
        required: true,
      },
    },
    fields: [
      "id",
      "quantity",
      "unfilledQuantity",
      "blockchainOrderId",
      "price",
      { wallet: ["address"] },
      "createdAt",
      "owner",
      "isInitialOrder",
      "status",
      { seller: ["initials", "profilePicture"] },
    ],
  }),
);

export const RECENT_SWEEP_ORDERS = gqlize(
  query({
    operation: "recentSweepOrders",
    variables: {
      artworkId: {
        type: "ID",
        required: true,
      },
    },
    fields: [
      "id",
      { buyer: ["initials", "profilePicture"] },
      { wallet: ["address"] },
      "maxQuantity",
      "maxPricePerToken",
      "maxTotalCost",
      "filledQuantity",
      "avgPrice",
      "totalCost",
      "status",
      "createdAt",
      "updatedAt",
    ],
  }),
);

export const SIMULATED_SWEEP = gqlize(
  query({
    operation: "simulatedSweep",
    variables: {
      artworkId: {
        type: "ID",
        required: true,
      },
      maxQuantity: {
        type: "Int",
        required: true,
      },
      maxPrice: {
        type: "Decimal",
        required: true,
      },
      maxTotalCost: {
        type: "Decimal",
        required: true,
      },
    },
    fields: [
      "totalCost",
      "maxPrice",
      "canFillAll",
      "filledQuantity",
      { ownOrders: ["id", "unfilledQuantity", "price"] },
      "ownOrdersFilledQuantity",
    ],
  }),
);

export const userArtworks = (artworkFields = BASE_ARTWORK_FIELDS) =>
  gqlize(
    query({
      operation: "me",
      fields: [{ ownedArtworks: artworkFields }, { coownedArtworks: artworkFields }],
    }),
  );

export const USER_SELL_ORDERS = gqlize(
  query({
    operation: "sellOrders",
    variables: {
      user: {
        type: "Boolean",
        required: true,
      },
    },
    fields: [
      "id",
      "price",
      "quantity",
      "status",
      { artwork: ["title", "slug", "imageLowRes", { gallery: ["slug", "title"] }] },
      "owner",
    ],
  }),
);

export const USER_TOTAL_ACQUISITIONS = gqlize(
  query({
    operation: "totalAcquisitions",
    fields: [
      "id",
      { artwork: ["title", "slug", "imageLowRes", { gallery: ["slug", "title"] }] },
      { wallet: ["address"] },
      "price",
      "status",
      "owner",
    ],
  }),
);

export const USER_SIGNED_CONTRACTS = gqlize(
  query({
    operation: "me",
    fields: ["signedOwner", "signedCoowner"],
  }),
);

export const USER_RENTALS = gqlize(
  query({
    operation: "userActiveRentals",
    variables: {
      artworkId: {
        type: "ID",
        required: true,
      },
    },
    fields: ["id", "startDate", "endDate"],
  }),
);

export const GEKART_POSITION = gqlize(
  query({
    operation: "gekartPosition",
    variables: {
      gekartId: {
        type: "String",
        required: true,
      },
    },
    fields: ["lat", "lon", "radius", "exact", "updatedAt"],
  }),
);

export const GEKART_ALARM_TICKET = gqlize(
  query({
    operation: "gekartAlarmTicket",
    variables: {
      gekartId: {
        type: "String",
        required: true,
      },
    },
    fields: ["status", "messageForCoOwners", "createdAt", "updatedAt", { updates: ["title", "message", "createdAt"] }],
  }),
);
