<template>
  <div style="width: 100%; height: 100%">
    <slot />
  </div>
</template>

<script>
export default {
  name: "NoneLayout",
};
</script>

<style scoped></style>
