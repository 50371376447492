<template>
  <v-avatar class="elevation-1" color="primary" v-bind="$attrs" v-on="$listeners">
    <ProfilePicture
      v-if="user && user.profilePicture && user.profilePicture.length > 0"
      :src="user.profilePicture" />
    <span v-else class="text-h6 white--text text-uppercase">
      {{ user.initials }}
    </span>
  </v-avatar>
</template>

<script>
import ProfilePicture from "@/components/ProfilePicture";

export default {
  name: "ProfileAvatar",
  components: { ProfilePicture },
  props: {
    user: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style scoped></style>
