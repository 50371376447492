const authModule = {
  state: () => ({
    logged: false
  }),
  mutations: {
    login (state) {
      state.logged = true;
    },
    async logout (state, apollo) {
      state.logged = false;
      await apollo?.provider.defaultClient.clearStore();
    }
  },
  getters: {
    isLogged (state) {
      return state.logged;
    }
  }
};

export default authModule;
